import * as React from "react";
import { useContext } from "react";

import { formatCurrencyString } from "~utils/i18n_utils/formatCurrencyString";
import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { SvgChartBad } from "~animations/__layers__/SvgChartBad";
import { SvgChartGood } from "~animations/__layers__/SvgChartGood";
import { SvgLogoBitcoin } from "~animations/__layers__/logos/SvgLogoBitcoin";
import { SvgLogoCronos } from "~animations/__layers__/logos/SvgLogoCronos";
import { SvgLogoEthereum } from "~animations/__layers__/logos/SvgLogoEthereum";
import { SvgLogoSolana } from "~animations/__layers__/logos/SvgLogoSolana";

import { PageQueryContext } from "~context/PageQueryContext";

import { AnimLayerUiCardTitle } from "../AnimLayerUiCardTitle";
import * as styles from "./styles.css";

import type { BoxProps } from "~components/Box";

/**
 * ------------------------------------------------------------------------------
 * Config for coins that will be shown in the UI mockup
 * ------------------------------------------------------------------------------
 */

export interface CoinListItemProps {
  coinName: string;
  coinAbbreviation: string;
  coinIconNode: React.ReactNode;
  coinChartNode: React.ReactNode;
  coinValue: number;
  coinChanged: number;
}

export const COIN_LIST_ITEMS = [
  {
    coinName: "Bitcoin",
    coinAbbreviation: "BTC",
    coinIconNode: <SvgLogoBitcoin />,
    coinChartNode: <SvgChartGood />,
    coinValue: 4400,
    coinChanged: 8.02,
  },
  {
    coinName: "Solana",
    coinAbbreviation: "SOL",
    coinIconNode: <SvgLogoSolana />,
    coinChartNode: <SvgChartBad />,
    coinValue: 443,
    coinChanged: -2.55,
  },
  {
    coinName: "Ethereum",
    coinAbbreviation: "ETH",
    coinIconNode: <SvgLogoEthereum />,
    coinChartNode: <SvgChartGood />,
    coinValue: 2350.11,
    coinChanged: 1.1,
  },
  {
    coinName: "Cronos",
    coinAbbreviation: "CRO",
    coinIconNode: <SvgLogoCronos />,
    coinChartNode: <SvgChartGood />,
    coinValue: 3876,
    coinChanged: 0.88,
  },
] as const;

/**
 * ------------------------------------------------------------------------------
 * Component for rendering a single coin list item
 * ------------------------------------------------------------------------------
 */

function CoinListItem({
  coinName,
  coinAbbreviation,
  coinChanged,
  coinIconNode,
  coinChartNode,
  coinValue,
}: CoinListItemProps) {
  const { pageContext } = useContext(PageQueryContext) || {};
  const { lang } = pageContext || {};

  return (
    <Box className={styles.coinListItem} data-motion={ANIMATED.secondary}>
      {coinIconNode}
      <Box textTransform="uppercase">
        <Box>{coinName}</Box>
        <Box color="text_lowContrast">{coinAbbreviation}</Box>
      </Box>
      {coinChartNode}
      <Box textAlign="right">
        <Box>
          {formatCurrencyString({
            amount: coinValue,
            lang,
          })}
        </Box>
        <Box color={coinChanged > 0 ? "semantic_green" : "semantic_red"}>
          {coinChanged > 0 ? `+${coinChanged}%` : `${coinChanged}%`}
        </Box>
      </Box>
    </Box>
  );
}

/**
 * ------------------------------------------------------------------------------
 * Main component
 * ------------------------------------------------------------------------------
 */

export interface AnimLayerCryptoCoinsProps extends Omit<BoxProps, "color"> {
  ctaText?: string;
  title?: string;
}

export function AnimLayerCryptoCoins({
  ctaText,
  title,
  ...rest
}: AnimLayerCryptoCoinsProps) {
  return (
    <StoryblokEditable {...rest}>
      <Box paddingX="spacing2" marginY="spacing2" overflow="hidden">
        <AnimLayerUiCardTitle title={title} ctaText={ctaText} />
        <Box textAppearance="body_sm">
          {COIN_LIST_ITEMS.map((item) => {
            return <CoinListItem key={item.coinName} {...item} />;
          })}
        </Box>
      </Box>
    </StoryblokEditable>
  );
}
