import React from "react";

import { faSearch } from "@fortawesome/sharp-regular-svg-icons/faSearch";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { StoryblokEditable } from "~components/StoryblokEditable";

import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimLayerSearchBarProps extends StoryblokBlok, BoxProps {
  title: string;
}

export function AnimLayerSearchBar({
  title,
  ...rest
}: AnimLayerSearchBarProps) {
  return (
    <StoryblokEditable {...rest}>
      <Box
        alignItems="center"
        border="default"
        borderRadius="sm"
        color="text_lowContrast"
        display="flex"
        gap="spacing1"
        padding="spacing1"
        textAppearance="body_sm"
        {...rest}
      >
        <IconFontAwesome icon={faSearch} />
        {title}
      </Box>
    </StoryblokEditable>
  );
}
