import React from "react";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { Box } from "~components/Box";

import { SvgLogoVisa } from "~animations/__layers__/logos/SvgLogoVisa";

import type { BoxProps } from "~components/Box";

export function AnimLayerCreditCardInfo({ ...rest }: BoxProps) {
  return (
    <Box
      className={variantBackgroundColor.background_sand}
      padding="spacing2"
      borderRadius="sm"
      alignItems="center"
      display="flex"
      gap="spacing1"
      textAppearance="body_sm"
      {...rest}
    >
      <SvgLogoVisa />
      <Box>**** 1234</Box>
      <Box marginLeft="auto">10/24</Box>
    </Box>
  );
}
