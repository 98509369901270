import React from "react";

import { faCalendarCircleExclamation } from "@fortawesome/sharp-regular-svg-icons/faCalendarCircleExclamation";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";

import { AnimLayerFakeButton } from "../AnimLayerFakeButton";

import type { AnimLayerFakeButtonProps } from "../AnimLayerFakeButton";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimLayerPaymentDueProps extends BoxProps {
  title?: string;
  description?: string;
  button1?: Array<StoryblokBlok & AnimLayerFakeButtonProps>;
  button2?: Array<StoryblokBlok & AnimLayerFakeButtonProps>;
}

export function AnimLayerPaymentDue({
  title,
  description,
  button1: button1BlokArray,
  button2: button2BlokArray,
  ...rest
}: AnimLayerPaymentDueProps) {
  const [button1] = button1BlokArray || [];
  const [button2] = button2BlokArray || [];

  return (
    <Box
      {...rest}
      className={variantBackgroundColor.background_sand}
      padding="spacing2"
      borderRadius="sm"
      display="flex"
      flexDirection="column"
      gap="spacing1"
    >
      <Box
        display="flex"
        alignItems="center"
        gap="spacing1"
        textAppearance="body_sm"
        marginBottom="spacing2"
      >
        <IconFontAwesome icon={faCalendarCircleExclamation} />
        <Box>
          {title && <Box fontWeight="semibold">{title}</Box>}
          {description && <Box>{description}</Box>}
        </Box>
      </Box>

      {button1 && <AnimLayerFakeButton size="sm" {...button1} />}
      {button2 && (
        <AnimLayerFakeButton size="sm" variant="secondary" {...button2} />
      )}
    </Box>
  );
}
