import React from "react";

import { faBolt } from "@fortawesome/sharp-regular-svg-icons/faBolt";
import { faBuildingColumns } from "@fortawesome/sharp-regular-svg-icons/faBuildingColumns";
import { faCheckCircle } from "@fortawesome/sharp-regular-svg-icons/faCheckCircle";
import { faFingerprint } from "@fortawesome/sharp-regular-svg-icons/faFingerprint";
import { faLock } from "@fortawesome/sharp-regular-svg-icons/faLock";
import { faShieldCheck } from "@fortawesome/sharp-regular-svg-icons/faShieldCheck";
import { faStopwatch } from "@fortawesome/sharp-regular-svg-icons/faStopwatch";
import clsx from "clsx";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { AnimatedElement } from "~animations/animation_primitives/AnimatedElement";

import { AnimLayerFakeButton } from "../AnimLayerFakeButton";
import { AnimLayerUiRadioButton } from "../AnimLayerUiRadioButton";
import { tagStyle } from "./styles.css";

import type { IconDefinition } from "@fortawesome/sharp-regular-svg-icons";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

/**
 * ------------------------------------------------------------------------------
 * Map of fontawesome icons
 * ------------------------------------------------------------------------------
 */

type IconMapKey = keyof typeof ICON_MAP;

const ICON_MAP: Record<string, IconDefinition> = {
  faCheckCircle,
  faBolt,
  faBuildingColumns,
  faStopwatch,
  faFingerprint,
  faShieldCheck,
  faLock,
} as const;

export interface AnimLayerListItemProps {
  title: string;
  icon: IconMapKey;
}

export interface AnimLayerInfoDialogProps extends BoxProps, StoryblokBlok {
  amount?: number;
  ctaText?: string;
  description?: string;
  icon?: IconMapKey;
  listItems?: Array<AnimLayerListItemProps & StoryblokBlok>;
  tag?: string;
  title?: string;
  isRadioButtonVisible?: boolean;
}

export function AnimLayerInfoDialog({
  className: userClassName,
  isRadioButtonVisible,
  icon = "faCheckCircle",
  listItems,
  title,
  ctaText,
  tag,
  ...rest
}: AnimLayerInfoDialogProps) {
  const dynamicIcon = ICON_MAP[icon];

  return (
    <StoryblokEditable {...rest}>
      <AnimatedElement
        animationLevel="primary"
        borderRadius="sm"
        className={clsx(userClassName, variantBackgroundColor.background_white)}
        height="min-content"
        padding="spacing2"
        position="relative"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap="spacing1"
        {...rest}
      >
        {tag && (
          <Box className={tagStyle} textAppearance="body_sm">
            {tag}
          </Box>
        )}

        <Box
          display="flex"
          flexDirection="column"
          rowGap="spacing1"
          width="100%"
        >
          <Box
            alignItems="center"
            data-motion={ANIMATED.secondary}
            display="flex"
            fontWeight="bold"
            gap="spacing1"
            textAppearance="body_md"
          >
            {dynamicIcon && <IconFontAwesome icon={dynamicIcon} />}
            {title && <Box marginY="spacing1">{title}</Box>}
          </Box>
          <Box data-motion={ANIMATED.secondary}>
            {Array.isArray(listItems) &&
              listItems.map((item) => {
                const dynamicItemIcon = ICON_MAP[item.icon];

                return (
                  <StoryblokEditable key={item._uid} {...item}>
                    <Box
                      key={item._uid}
                      alignItems="center"
                      color="text_lowContrast"
                      display="flex"
                      gap="spacing1"
                      marginBottom="spacing1"
                      textAppearance="body_sm"
                    >
                      {dynamicItemIcon && (
                        <IconFontAwesome icon={dynamicItemIcon} />
                      )}
                      {item.title && <Box>{item.title}</Box>}
                    </Box>
                  </StoryblokEditable>
                );
              })}
          </Box>
          {ctaText && (
            <AnimLayerFakeButton
              data-motion={ANIMATED.secondary}
              size="sm"
              ctaIcon="faArrowRightLong"
            >
              {ctaText}
            </AnimLayerFakeButton>
          )}
        </Box>

        {isRadioButtonVisible && <AnimLayerUiRadioButton isChecked />}
      </AnimatedElement>
    </StoryblokEditable>
  );
}
